<template lang="pug">
  router-view
</template>

<script>
export default {
  name: 'FarmStorage'
}
</script>

<style lang="scss" scoped>
</style>
